define("discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-edits", ["exports", "@glimmer/component", "discourse/lib/plugin-api", "discourse-common/lib/deprecated", "I18n", "discourse/plugins/discourse-post-voting/discourse/components/post-voting-answer-button", "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments", "@ember/component", "@ember/template-factory"], function (_exports, _component, _pluginApi, _deprecated, _I18n, _postVotingAnswerButton, _postVotingComments, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ORDER_BY_ACTIVITY_FILTER = void 0;
  const ORDER_BY_ACTIVITY_FILTER = _exports.ORDER_BY_ACTIVITY_FILTER = "activity";
  const pluginId = "discourse-post-voting";
  function initPlugin(api1, container1) {
    customizePostMenu(api1, container1);
    api1.modifyClass("model:post-stream", {
      pluginId,
      orderStreamByActivity() {
        this.cancelFilter();
        this.set("filter", ORDER_BY_ACTIVITY_FILTER);
        return this.refreshAndJumpToSecondVisible();
      },
      orderStreamByVotes() {
        this.cancelFilter();
        return this.refreshAndJumpToSecondVisible();
      }
    });
    function customLastUnreadUrl1(context1) {
      if (context1.is_post_voting && context1.last_read_post_number) {
        if (context1.highest_post_number <= context1.last_read_post_number) {
          // link to OP if no unread
          return context1.urlForPostNumber(1);
        } else if (context1.last_read_post_number === context1.highest_post_number - 1) {
          return context1.urlForPostNumber(context1.last_read_post_number + 1);
        } else {
          // sort by activity if user has 2+ unread posts
          return `${context1.urlForPostNumber(context1.last_read_post_number + 1)}?filter=activity`;
        }
      }
    }
    api1.registerCustomLastUnreadUrlCallback(customLastUnreadUrl1);
    api1.reopenWidget("post", {
      orderByVotes() {
        this._topicController().model.postStream.orderStreamByVotes().then(() => {
          this._refreshController();
        });
      },
      orderByActivity() {
        this._topicController().model.postStream.orderStreamByActivity().then(() => {
          this._refreshController();
        });
      },
      _refreshController() {
        this._topicController().updateQueryParams();
      },
      _topicController() {
        return this.register.lookup("controller:topic");
      }
    });
    api1.decorateWidget("post-article:before", helper1 => {
      const result1 = [];
      const post1 = helper1.getModel();
      if (!post1.topic.is_post_voting) {
        return result1;
      }
      const topicController1 = helper1.widget.register.lookup("controller:topic");
      let positionInStream1;
      if (topicController1.replies_to_post_number && parseInt(topicController1.replies_to_post_number, 10) !== 1) {
        positionInStream1 = 2;
      } else {
        positionInStream1 = 1;
      }
      const answersCount1 = post1.topic.posts_count - 1;
      if (answersCount1 <= 0 || post1.id !== post1.topic.postStream.stream[positionInStream1]) {
        return result1;
      }
      result1.push(helper1.h("div.post-voting-answers-header.small-action", [helper1.h("span.post-voting-answers-headers-count", _I18n.default.t("post_voting.topic.answer_count", {
        count: answersCount1
      })), helper1.h("span.post-voting-answers-headers-sort", [helper1.h("span", _I18n.default.t("post_voting.topic.sort_by")), helper1.attach("button", {
        action: "orderByVotes",
        contents: _I18n.default.t("post_voting.topic.votes"),
        disabled: topicController1.filter !== ORDER_BY_ACTIVITY_FILTER,
        className: `post-voting-answers-headers-sort-votes ${topicController1.filter === ORDER_BY_ACTIVITY_FILTER ? "" : "active"}`
      }), helper1.attach("button", {
        action: "orderByActivity",
        contents: _I18n.default.t("post_voting.topic.activity"),
        disabled: topicController1.filter === ORDER_BY_ACTIVITY_FILTER,
        className: `post-voting-answers-headers-sort-activity ${topicController1.filter === ORDER_BY_ACTIVITY_FILTER ? "active" : ""}`
      })])]));
      return result1;
    });
    api1.decorateWidget("post-avatar:after", function (helper1) {
      const result1 = [];
      const model1 = helper1.getModel();
      if (model1.topic?.is_post_voting) {
        const postVotingPost1 = helper1.attach("post-voting-post", {
          count: model1.get("post_voting_vote_count"),
          post: model1
        });
        result1.push(postVotingPost1);
      }
      return result1;
    });
    api1.includePostAttributes("comments", "comments_count", "post_voting_user_voted_direction", "post_voting_has_votes");
  }
  function customizePostMenu(api, container) {
    const siteSettings = container.lookup("service:site-settings");
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag1,
        context: {
          post: post1,
          buttonKeys: buttonKeys1
        }
      } = _ref;
      if (post1.post_voting_has_votes !== undefined) {
        dag1.add("post-voting-answer", _postVotingAnswerButton.default, {
          after: [buttonKeys1.SHOW_MORE, buttonKeys1.REPLY]
        });
        dag1.delete(buttonKeys1.REPLY);
        if (post1.post_number !== 1 && !siteSettings.post_voting_enable_likes_on_answers) {
          dag1.delete(buttonKeys1.LIKE);
        }
      }
    });
    api.renderInOutlet("post-menu__after", class extends _component.default {
      static shouldRender(args1) {
        return args1.post.post_voting_has_votes !== undefined && !args1.post.reply_to_post_number && !(args1.state.filteredRepliesView && args1.state.repliesShown);
      }
      static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        
              <PostVotingComments
                @post={{@outletArgs.post}}
                @canCreatePost={{@outletArgs.state.canCreatePost}}
              />
            
      */
      {
        "id": "kUceQ+FN",
        "block": "[[[1,\"\\n        \"],[8,[32,0],null,[[\"@post\",\"@canCreatePost\"],[[30,1,[\"post\"]],[30,1,[\"state\",\"canCreatePost\"]]]],null],[1,\"\\n      \"]],[\"@outletArgs\"],false,[]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-edits.js",
        "scope": () => [_postVotingComments.default],
        "isStrictMode": true
      }), this))();
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetPostMenu(api));
  }
  function customizeWidgetPostMenu(api1) {
    api1.removePostMenuButton("reply", attrs1 => {
      return attrs1.post_voting_has_votes !== undefined;
    });
    api1.removePostMenuButton("like", (_attrs1, _state1, siteSetting1) => {
      return _attrs1.post_voting_has_votes !== undefined && _attrs1.post_number !== 1 && !siteSetting1.post_voting_enable_likes_on_answers;
    });
    api1.addPostMenuButton("answer", attrs1 => {
      if (attrs1.post_voting_has_votes === undefined || attrs1.post_number !== 1 || !attrs1.canCreatePost) {
        return;
      }
      const args1 = {
        action: "replyToPost",
        title: "post_voting.topic.answer.help",
        icon: "reply",
        className: "reply create fade-out",
        position: "last"
      };
      if (!attrs1.mobileView) {
        args1.label = "post_voting.topic.answer.label";
      }
      return args1;
    });
    api1.decorateWidget("post-menu:after", helper1 => {
      const result1 = [];
      const attrs1 = helper1.widget.attrs;
      if (attrs1.post_voting_has_votes !== undefined && !attrs1.reply_to_post_number && !helper1.widget.state.filteredRepliesShown) {
        const post1 = helper1.getModel();
        result1.push(helper1.attach("post-voting-comments", {
          post: post1,
          canCreatePost: attrs1.canCreatePost
        }));
      }
      return result1;
    });
  }
  var _default = _exports.default = {
    name: "post-voting-edits",
    initialize(container1) {
      const siteSettings1 = container1.lookup("service:site-settings");
      if (!siteSettings1.post_voting_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.13.0", api1 => {
        initPlugin(api1, container1);
      });
    }
  };
});